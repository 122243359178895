import { Route, Routes } from "react-router-dom";

import SigveTech from "./sigve.tech/Component";
import Divit from "./divit.sigve.tech/Component";
import Media from "./media.sigve.tech/Component";
import Christmas2023 from "./jul2023/Component";

function ProjectRouter() {
  return (
    <Routes>
      <Route path="/sigve.tech" Component={SigveTech} />
      <Route path="/divit.sigve.tech" Component={Divit} />
      <Route path="/media.sigve.tech" Component={Media} />
      <Route path="/jul2023" Component={Christmas2023} />
    </Routes>
  );
}

export default ProjectRouter;
