import moment from "moment";
import { Divider, Typography } from "@mui/material";
import Page, { PageProps } from "./Page";
import { Project, projects } from "../pages/data/data";

interface ProjectPageProps extends PageProps {
  id: string;
}

function ProjectPage(p: ProjectPageProps) {
  const project = projects.find((a) => a.id === p.id) as Project;

  return (
    <Page
      navigation={[
        {
          label: project.id,
          url: project.url,
        },
        ...(p.navigation ?? []),
      ]}
    >
      <Typography variant="h1">{project.title}</Typography>
      <Typography whiteSpace="pre">
        Opprettet: {"  "}
        {moment(project.createdAt).format("DD.MM.YYYY")}
        <br />
        Sist endret: {moment(project.modifiedAt).format("DD.MM.YYYY")}
      </Typography>
      <Divider sx={{ borderWidth: "1.5px", marginY: 2 }} />
      <Typography variant="h4">Beskrivelse:</Typography>
      {project.description}
      <Divider sx={{ borderWidth: "1.5px", marginY: 2 }} />
      {p.children}
    </Page>
  );
}

export default ProjectPage;
