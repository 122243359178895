import { useMemo } from "react";
import { Link } from "react-router-dom";
import Page from "../components/Page";
import ViewProject from "../components/ViewProject";
import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Update, projects, updates } from "./data/data";
import moment from "moment";

function Home() {
  const latestUpdate = useMemo(
    () =>
      updates.reduce(
        (a, b) => ((a?.at ?? "") < b.at ? b : a),
        undefined as Update | undefined,
      ),
    [],
  );
  const sortedProjects = useMemo(
    () => projects.sort((a, b) => (a.modifiedAt > b.modifiedAt ? -1 : 1)),
    [],
  );

  return (
    <Page>
      {latestUpdate ? (
        <Alert
          severity={latestUpdate?.severity ?? "info"}
          sx={{ marginBottom: 2 }}
        >
          <AlertTitle>{latestUpdate?.title}</AlertTitle>
          {latestUpdate?.description}
          <Box flex={1} />
          <Link to="/endringer">Flere endringer</Link>
        </Alert>
      ) : null}
      <Typography variant="h3">Nylig oppdaterte prosjekter</Typography>
      <Grid container>
        <Grid item xs={12} md={8} container>
          {sortedProjects.slice(0, 4).map((project) => (
            <ViewProject key={project.id} {...project} xl={6} lg={6} md={6} />
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            sx={{
              background: "#bbbbbb",
              height: { xs: "110px", md: "188px" },
              padding: 2,
              display: { xs: "none", sm: "none", md: "flex" },
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            {sortedProjects.slice(4).map((project) => (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2">
                  {moment(project.modifiedAt).format("DD.MM.YYYY")}
                </Typography>
                <Link key={project.id} to={`/prosjekt/${project.id}`}>
                  {project.title}
                </Link>
              </Stack>
            ))}
          </Paper>
        </Grid>
      </Grid>
      <Typography variant="h5">
        <Link to="/prosjekter">Se alle prosjekter</Link>
      </Typography>
    </Page>
  );
}

export default Home;
