import { AlertColor } from "@mui/material";
import projectsJson from "./projects.json";
import updatesJson from "./updates.json";

export interface Project {
  id: string;
  title: string;
  description?: string;
  url?: string;
  createdAt: string; // YYYY-MM-DD
  modifiedAt: string; // YYYY-MM-DD
}

export interface Update {
  title: string;
  description?: string;
  at: string; // YYYY-MM-DD
  severity?: AlertColor;
}

export const projects = projectsJson as Project[];
export const updates = updatesJson as Update[];
