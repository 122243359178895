import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import { Project } from "../pages/data/data";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface ViewProjectProps extends Project {
  useCreatedDate?: boolean;
  xl?: number;
  lg?: number;
  md?: number;
}

function ViewProject(p: ViewProjectProps) {
  return (
    <Grid
      item
      xl={p.xl ?? 3}
      lg={p.lg ?? 4}
      md={p.md ?? 6}
      xs={12}
      height="110px"
      padding={2}
    >
      <Stack direction="row">
        <Link
          to={`/prosjekt/${p.id}`}
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "pre",
          }}
        >
          <Typography variant="h4">{p.title}</Typography>
        </Link>
        {p.url ? (
          <IconButton size="small" href={p.url} target="_blank">
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        ) : null}
      </Stack>
      <Typography
        sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre" }}
      >
        {p.description ?? ""}
      </Typography>
      <Typography>
        {p.useCreatedDate ? "Opprettet" : "Sist endret"}{" "}
        {moment(p.useCreatedDate ? p.createdAt : p.modifiedAt).format(
          "DD.MM.YYYY",
        )}
      </Typography>
    </Grid>
  );
}

export default ViewProject;
