import { useEffect, useRef, useState } from "react";

interface Snowflake {
  x: number;
  y: number;
  size: number;
  speed: number;
  rotation: number;
  display: string | HTMLImageElement;
}

interface SnowEffectProps {
  width?: number;
  height?: number;
  style?: any;
}

function SnowEffect(p: SnowEffectProps) {
  const { width = 1200, height = 700 } = p;

  const ref = useRef<HTMLCanvasElement | null>(null);

  const [flakes, setFlakes] = useState<Snowflake[]>([]);
  const [image, setImage] = useState<HTMLImageElement>();
  const [isFast, setIsFast] = useState<boolean>(false);

  const update = async () => {
    const startedAt = Date.now();
    const canvas = ref.current;
    const context = canvas?.getContext("2d");
    if (canvas && context) {
      isFast && (await new Promise((r) => setTimeout(r, 20)));
      canvas.width = width;
      canvas.height = height;
      context.clearRect(0, 0, canvas.width, canvas.height);

      var newFlakes = [...flakes];

      if (newFlakes.length < 100) {
        newFlakes.push({
          x: Math.random() * canvas.width,
          y: -50,
          size: Math.random() * 5 * 2 + 30,
          speed: ((Math.random() + 1) * 3) / 2,
          rotation: Math.random() * 360,
          display: [
            "❄︎",
            "❆",
            "❅",
            (Math.floor(Math.random() * 10) === 1 ? image : undefined) ?? "❄︎",
          ][Math.floor(Math.random() * 4)],
        } as Snowflake);
      }

      for (const flake of newFlakes) {
        context.save();
        context.fillStyle = "#fff";
        context.font = `bold ${flake.size}px arial`;
        context.textAlign = "center";
        context.translate(flake.x, flake.y);
        context.rotate(flake.rotation);
        if (typeof flake.display === "string") {
          context.fillText(flake.display, 0, 0);
        } else {
          context.drawImage(flake.display, 0, 0, flake.size, flake.size);
        }
        context.restore();
        flake.y += flake.speed;
      }

      context.fillStyle = "#5f5";
      context.font = "bold 128px CodeNewRomanMono";
      context.textAlign = "center";
      context.fillText("God jul", canvas.width / 2, canvas.height / 2);

      newFlakes = newFlakes.filter((f) => f.y < canvas.height + 50);

      setFlakes(newFlakes);
    }
    const slow = Date.now() - startedAt > 10;
    setIsFast(!slow);
  };

  useEffect(() => {
    const img = document.createElement("img");
    img.src = "https://media.sigve.tech/logo/hvit_bakgrunn.png";
    setImage(img);
  }, []);

  // Should always be triggered
  useEffect(() => {
    update();
    // eslint-disable-next-line
  }, [flakes]);

  return (
    <canvas
      style={{
        background: "#121212",
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        ...p.style,
      }}
      ref={ref}
    />
  );
}

export default SnowEffect;
