import { HashRouter, Route, Routes } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Link,
  Stack,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AppTheme from "./AppTheme";

import Home from "./pages/Home";
import Projects from "./pages/Projects";
import About from "./pages/About";
import ProjectRouter from "./pages/projects/Router";
import Updates from "./pages/Updates";

function Router() {
  const smallerScreen = useMediaQuery(AppTheme.breakpoints.down("md"));

  return (
    <ThemeProvider theme={AppTheme}>
      <Stack
        component="header"
        direction="row"
        spacing={1}
        alignItems="center"
        height="57px"
        borderBottom={`3px solid ${AppTheme.palette.divider}`}
        paddingX={2}
      >
        {!smallerScreen ? (
          <Typography
            variant="h3"
            fontFamily="CodeNewRomanMono"
            color="primary"
          >
            <Link href="#" color="inherit">
              sigve.tech
            </Link>
          </Typography>
        ) : (
          <IconButton href="#" size="small">
            <img
              alt="logo"
              src="https://media.sigve.tech/logo/hvit_bakgrunn.png"
              width="40px"
            />
          </IconButton>
        )}
        <Box flex={1} />
        <ButtonGroup variant="contained">
          <Button href="#">Hjem</Button>
          <Button href="#/prosjekter">Prosjekter</Button>
          <Button href="#/om">Om</Button>
        </ButtonGroup>
      </Stack>
      <Box minHeight="calc(100vh - 175px)">
        <HashRouter>
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/prosjekter" Component={Projects} />
            <Route path="/prosjekt/*" Component={ProjectRouter} />
            <Route path="/om" Component={About} />
            <Route path="/endringer" Component={Updates} />
          </Routes>
        </HashRouter>
      </Box>
      <Stack
        component="footer"
        direction="row"
        spacing={2}
        height="80px"
        padding={2}
        borderTop={`3px solid ${AppTheme.palette.divider}`}
        sx={{ background: "#eeeeee" }}
      >
        <Stack spacing={2} flex={1}></Stack>
        <Stack spacing={2} flex={1}></Stack>
        <Stack spacing={2} flex={1} alignItems="flex-end">
          <img
            alt="logo"
            src="https://media.sigve.tech/favicon/logo192.png"
            height="80px"
          />
        </Stack>
      </Stack>
    </ThemeProvider>
  );
}

export default Router;
