import { useMemo, useState } from "react";
import {
  Autocomplete,
  FormLabel,
  Grid,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import ViewProject from "../components/ViewProject";
import { projects } from "./data/data";

import Page from "../components/Page";

enum SortBy {
  CREATED = "Opprettet",
  MODIFIED = "Sist endret",
}

enum SortDirection {
  ASC = "Stigende",
  DESC = "Synkende",
}

function Projects() {
  const theme = useTheme();
  const [search, setSearch] = useState<string>("");
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.CREATED);
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.DESC,
  );

  const filteredProjects = useMemo(() => {
    let newProjects = [...projects];

    if (search.length) {
      const searchAsLower = search.toLowerCase();
      newProjects = newProjects.filter(
        (project) =>
          project.id.toLowerCase().includes(searchAsLower) ||
          project.title.toLowerCase().includes(searchAsLower) ||
          project.url?.toLowerCase().includes(searchAsLower) ||
          project.description?.toLowerCase().includes(searchAsLower),
      );
    }

    newProjects = newProjects.sort((a, b) => {
      switch (sortBy) {
        case SortBy.CREATED:
          return a.createdAt > b.createdAt ? 1 : -1;
        case SortBy.MODIFIED:
          return a.modifiedAt > b.modifiedAt ? 1 : -1;
        default:
          return -1;
      }
    });

    return sortDirection === SortDirection.DESC
      ? newProjects.reverse()
      : newProjects;
  }, [search, sortBy, sortDirection]);

  return (
    <Page>
      <Grid
        container
        spacing={2}
        paddingX={2}
        paddingY={0}
        alignItems="flex-end"
      >
        <Grid item xs={12} md={6} xl={5}>
          <FormLabel
            htmlFor="search"
            sx={{ color: "black", ...theme.typography.h4 }}
          >
            Filtrer prosjekter
          </FormLabel>
          <Stack direction="row" spacing={2}>
            <TextField
              id="search"
              role="search"
              size="small"
              variant="outlined"
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
              placeholder='f.eks. "jul2023", "verktøy"'
              fullWidth
            />
          </Stack>
        </Grid>
        <Grid item xs={7} md={3} xl={1.5}>
          <Autocomplete<SortBy, false, true>
            id="sort-by"
            size="small"
            value={sortBy ?? null}
            onChange={(e, v) => setSortBy(v)}
            renderInput={(params) => (
              <TextField variant="outlined" {...params} />
            )}
            options={[SortBy.CREATED, SortBy.MODIFIED]}
            getOptionLabel={(a) => a.valueOf()}
            disableClearable
          />
        </Grid>
        <Grid item xs={5} md={3} xl={1.5}>
          <Autocomplete<SortDirection, false, true>
            id="sort-direction"
            size="small"
            value={sortDirection ?? null}
            onChange={(e, v) => setSortDirection(v)}
            renderInput={(params) => (
              <TextField variant="outlined" {...params} />
            )}
            options={[SortDirection.ASC, SortDirection.DESC]}
            getOptionLabel={(a) => a.valueOf()}
            disableClearable
          />
        </Grid>
      </Grid>
      <Grid container>
        {filteredProjects.map((project) => (
          <ViewProject
            key={project.id}
            {...project}
            useCreatedDate={sortBy === SortBy.CREATED}
          />
        ))}
      </Grid>
    </Page>
  );
}

export default Projects;
