import { Alert, AlertTitle, Box, Stack, Typography } from "@mui/material";
import Page from "../components/Page";
import { updates } from "./data/data";
import moment from "moment";

function Updates() {
  return (
    <Page>
      <Typography variant="h3">Endringer</Typography>
      <Stack spacing={1} marginY={1}>
        {updates
          .sort((a, b) => (a.at > b.at ? -1 : 1))
          .map((update) => (
            <Alert severity={update.severity ?? "info"} sx={{ flex: 1 }}>
              <AlertTitle>{update.title}</AlertTitle>
              {update.description}
              <Box flex={1} />
              {moment(update.at).format("DD.MM.YYYY")}
            </Alert>
          ))}
      </Stack>
    </Page>
  );
}

export default Updates;
