import { Stack } from "@mui/material";
import SnowEffect from "./SnowEffect";

export default function Component() {
  return (
    <Stack>
      <SnowEffect width={2000} height={1000} />
    </Stack>
  );
}
