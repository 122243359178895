import { useState } from "react";
import { IconButton, Link, Stack, Typography } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export interface Node {
  label: string;
  url?: string;
  nodes?: Node[];
}

export function RenderNode(p: { node: Node; layer?: number }) {
  const [expanded, setExpanded] = useState<Boolean>(false);

  return (
    <>
      <Stack
        direction="row"
        width="100%"
        marginLeft={3 * (p.layer ?? 0)}
        sx={p.node.url ? { ":hover": { background: "action.hover" } } : {}}
      >
        {p.node.nodes?.length ? (
          <IconButton
            size="small"
            sx={{ padding: 0, marginRight: 0.5 }}
            onClick={() => setExpanded((s) => !s)}
          >
            {expanded ? (
              <KeyboardArrowDownIcon fontSize="small" />
            ) : (
              <KeyboardArrowRightIcon fontSize="small" />
            )}
          </IconButton>
        ) : null}
        {p.node.url ? (
          <Link
            href={p.node.url}
            target={p.node.url.match(/^https?:\/\//i) ? "_blank" : undefined}
          >
            <Typography variant="h5">
              {p.node.label}
              {p.node.url.match(/^https?:\/\//i) ? (
                <OpenInNewIcon
                  fontSize="inherit"
                  sx={{ marginTop: 0.5, marginBottom: -0.5 }}
                />
              ) : null}
            </Typography>
          </Link>
        ) : (
          <Typography variant="h5">{p.node.label}</Typography>
        )}
      </Stack>
      {expanded && p.node.nodes
        ? p.node.nodes.map((node) => (
            <RenderNode node={node} layer={(p.layer ?? 0) + 1} />
          ))
        : null}
    </>
  );
}

export function Sidebar(p: { nodes: Node[] }) {
  return (
    <Stack
      width="250px"
      padding={2}
      sx={{
        background: "#bbbbbb",
        height: "calc(100vh - 207px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {p.nodes.map((node) => (
        <RenderNode key={node.label} node={node} />
      ))}
    </Stack>
  );
}

export function Topbar(p: { nodes: Node[] }) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Stack
      borderRadius={2}
      sx={{
        background: "#bbbbbb",
        padding: 1,
        marginX: "4px !important",
        marginY: "6px !important",
      }}
    >
      <Typography
        variant="h5"
        role="button"
        onClick={() => setOpen((s) => !s)}
        sx={{
          cursor: "pointer",
          width: "100%",
        }}
      >
        Sidemeny
      </Typography>
      {open ? (
        <>
          {p.nodes.map((node) => (
            <RenderNode key={node.label} node={node} />
          ))}
        </>
      ) : null}
    </Stack>
  );
}
