import { useMemo } from "react";
import { Node, Sidebar, Topbar } from "./Appbars";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";

export interface PageProps {
  navigation?: Node[];
  children: any;
}

function Page(p: PageProps) {
  const theme = useTheme();
  const topbar = useMediaQuery(theme.breakpoints.down("md"));

  const body = useMemo(
    () => (
      <Box padding={2} width={topbar ? undefined : "calc(100% - 32px)"}>
        {p.children}
      </Box>
    ),
    [p.children, topbar],
  );

  return p.navigation ? (
    <Stack direction={topbar ? "column" : "row"} maxWidth="100vw" spacing={1}>
      {topbar ? (
        <Topbar nodes={p.navigation} />
      ) : (
        <Sidebar nodes={p.navigation} />
      )}
      {body}
    </Stack>
  ) : (
    body
  );
}

export default Page;
