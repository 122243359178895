import { createTheme } from "@mui/material";

const AppTheme = createTheme({
  palette: {
    primary: {
      light: "#fc754c",
      main: "#f1592a",
      dark: "#d54b22",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#cfa38a",
      main: "#9a6848",
      dark: "#774527",
      contrastText: "#ffffff",
    },
    divider: "#cccccc",
  },
  typography: {
    fontFamily: "CodeNewRoman",
    h1: { fontSize: "50px" },
    h2: { fontSize: "40px" },
    h3: { fontSize: "30px" },
    h4: { fontSize: "24px" },
    h5: { fontSize: "18px" },
    h6: { fontSize: "12px" },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default AppTheme;
